import { Flex } from '@chakra-ui/layout';
import useInquiryFormSubmitted from '@hooks/use-inquiry-form-submitted';
import { useTenantFunctions } from '~/contexts/TenantFunctionsContext';
import SearchResult from '../Search/SearchResult';
import { modifyTrailingSlash } from '~/utils/modifyTrailingSlash';
import { calculateProviderMinPrice } from '~/utils/providers';
import { Section } from '@components/Sections';
import { HeadingElements } from '~/@types/heading';
import Container from '@components/LayoutStructure/Container';
import { Color } from '@utils/getColor';
import { Provider } from '~/contexts/Provider';

export type FetchResponse = {
  providerResults: Provider[];
};

export interface FeaturedProvidersProps {
  title: string;
  headingElement: HeadingElements;
  text: string;
  textAlignment?: 'left' | 'center' | 'right';
  textColor?: Color;
  data: FetchResponse;
  providers: {
    '@nodes': string[];
    providers0: { slug: string };
    providers1?: { slug: string };
    providers2?: { slug: string };
    providers3?: { slug: string };
    providers4?: { slug: string };
    providers5?: { slug: string };
    providers6?: { slug: string };
  };
  enableTrailingSlash?: boolean;
  providerTitleColor?: Color;
  displayBadges?: boolean;
  displayLearnMoreButton?: boolean;
  learnMoreButtonText?: string;
  displayRequestInfoButton?: boolean;
  requestInfoButtonText?: string;
  learnMoreButton?: string;
  inquiryId?: string;
  requestInfoButtonColorScheme?: string;
  learnMoreButtonColorScheme?: string;
  ratingStarsColor?: Color;
  dontOpenInNewTab: boolean;
}

const FeaturedProviders = ({
  title,
  headingElement,
  text,
  textAlignment,
  textColor = { color: 'black', range: '500' },
  data,
  enableTrailingSlash = false,
  providerTitleColor = { color: 'primary', range: '800' },
  displayBadges = false,
  displayLearnMoreButton,
  learnMoreButtonText,
  displayRequestInfoButton = false,
  requestInfoButtonText,
  learnMoreButton,
  inquiryId,
  requestInfoButtonColorScheme,
  learnMoreButtonColorScheme,
  ratingStarsColor = { color: 'primary', range: '800' },
  dontOpenInNewTab,
}: FeaturedProvidersProps): JSX.Element => {
  const { getProviderDetailsPath } = useTenantFunctions();
  const showPrice = useInquiryFormSubmitted();
  const sectionAttributes = {
    textAlignment: textAlignment,
    richTextColor: textColor.color,
    richTextColorRange: textColor.range,
    titleAlignment: textAlignment,
    titleColor: textColor.color,
    titleColorRange: textColor.range,
  };

  if (data.providerResults.length === 0) {
    return <></>;
  }
  const renderSearchResult = (result: Provider) => {
    if (result?.meta?.statusCode === 404) return null;
    const minPrice = calculateProviderMinPrice({ result });
    const path = modifyTrailingSlash(
      enableTrailingSlash,
      getProviderDetailsPath(result)
    );
    const images = result.images ?? result?.photos?.map((photo) => photo.url);
    const formattedAddress = `${result.address?.street}, <br/> ${result.address?.city}, ${result.address?.state} ${result.address?.zipCode}`;
    return (
      <SearchResult
        key={result.id}
        id={result.id}
        legacyId={result.legacyId ?? result.id}
        title={result.name}
        address={formattedAddress}
        images={images ?? []}
        averageRating={result.averageRating}
        reviewCount={result.reviewCount}
        price={minPrice}
        showPrice={showPrice}
        element={'p'}
        path={path}
        caringStars={result?.awards || []}
        providerTitleColor={providerTitleColor.color}
        providerTitleColorRange={providerTitleColor.range}
        displayBadges={displayBadges}
        displayLearnMoreButton={displayLearnMoreButton}
        learnMoreButtonText={learnMoreButtonText}
        displayRequestInfoButton={displayRequestInfoButton}
        requestInfoButtonText={requestInfoButtonText}
        readMoreButton={learnMoreButton}
        modalId={inquiryId}
        requestInfoButtonColorScheme={requestInfoButtonColorScheme}
        learnMoreButtonColorScheme={learnMoreButtonColorScheme}
        ratingStarsColor={ratingStarsColor.color}
        ratingStarsColorRange={ratingStarsColor.range}
        showVerifiedBadge={result.enhancedListing}
        width={'100%'}
        dontOpenInNewTab={dontOpenInNewTab}
      />
    );
  };

  return (
    <Container>
      <Section
        headingElement={headingElement}
        richText={text}
        title={title}
        {...sectionAttributes}
      />
      <Flex mt={8} gap={8} flexWrap="wrap" justifyContent="space-evenly">
        {data.providerResults?.map(renderSearchResult)}
      </Flex>
    </Container>
  );
};

export default FeaturedProviders;
